
export default {
  name: 'OrganismAnchors',
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    anchors: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      instance: null,
      options: {
        perPage: 4,
        pagination: false,
        gap: '1rem',
        height: '15rem',
        padding: '4.375rem',
        arrows: true,
        breakpoints: {
          1200: {
            perPage: 4
          },
          900: {
            perPage: 2,
            padding: '1.25rem'
          },
          767: {
            perPage: 1
          }
        }
      }
    }
  }
}
